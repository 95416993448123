<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)"  class="col-sm-6">
                    <h1>{{ $t('general.proveedor') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>{{ $t('general.nuevoproveedor') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div class="col-md-2" v-if="(id > 0)">
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ proveedor.nombre_proveedor }}</h3>
                            <p class="text-muted text-center text-white">{{ proveedor.ciudad_proveedor }}</p>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{ $t('general.expedientesactivos') }}</b> <a class="float-right">5</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                         <h5>{{ $t('general.ultimamodificacion') }}</h5>
                         <p>{{ proveedor.fecha_actualiza_proveedor }}</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="active tab-pane" id="proveedor_datos">
                        <proveedor-datos :proveedor="proveedor" :id="id" /> 
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import ProveedorDatos from './datos/ProveedorDatos.vue'
export default {
    props: ['id'],
    components: {
        'proveedor-datos': ProveedorDatos,
    },
    computed: {
        editandoProveedor() {
            return this.id < 0;
        },
    },
    data() {
        return {
            proveedor: [],
            nuevo: 'false'
        }
    },
    methods: {
        async obtenerProveedor(id) {
            /* get  modulos/pwgsapi/index.php/proveedores/:id */   
                const api = new PwgsApi;
                const datos = await api.get('proveedores/' + id);
            this.proveedor = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/proveedores/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.proveedor.nombre_proveedor;
                    this.$store.getters.ventanas[i].alt = "Proveedor: " + this.proveedor.nombre_proveedor + " || " + this.proveedor.ciudad_proveedor;
                }
            }
            /**/
        },
        cambios() {
            this.obtenerProveedor(this.id);
        },
        recargar() {
            this.obtenerProveedor(this.id);
        },
    },  
    mounted() {
        this.$store.dispatch('cambiarCargando', true);
        this.obtenerProveedor(this.id);
     
        this.$store.dispatch('cambiarCargando', false);
    },
    watch: {
        id() {
            this.obtenerProveedor(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/proveedores/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.proveedor.nombre_proveedor;
                    this.$store.getters.ventanas[i].alt = "Proveedor: " + this.proveedor.nombre_proveedor +" || "+ this.proveedor.ciudad_proveedor;
                }
            }
            /**/
        }
    }
}
</script>
